import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Polygon } from "@fitplan/lib/components/Chevron/Polygon";
import useSetState from "react-use/lib/useSetState";
import axios from "axios";

import {Container, Input, Title, TextArea, Button, Error, Body, Note} from "../../components/ContactForm";
import PageWrapper from "../../components/pageWrapper/PageWrapper";
import { urls } from "../../config";

export interface Props {}

const ContactPR: React.SFC<Props> = (props) => {
    const { contactFormPr: strings } = useStaticQuery(graphql`
        query ContactFormPR {
            contactFormPr {
                title
                namePlaceholder
                emailPlaceholder
                phoneNumberPlaceholder
                companyNamePlaceholder
                messagePlaceholder
                validationError
                unknownError
                emailError
                phoneError
                submitButton
                finishedTitle
                finishedBody
                requiredNote
            }
        }
    `);
    const [state, setState] = useSetState({
        name: "",
        email: "",
        phoneNumber: "",
        companyName: "",
        message: "",
        error: ""
    });
    const [finished, setFinished] = React.useState(false);
    return (
        <PageWrapper {...props}>
            <Container>
                {finished ? (
                    <>
                        <Title>{strings.finishedTitle}</Title>
                        <Body>{strings.finishedBody}</Body>
                    </>
                ) : (
                    <>
                        <Title>
                            {strings.title}
                        </Title>
                        <Input
                            type="text"
                            tabIndex={10}
                            value={state.name}
                            placeholder={`${strings.namePlaceholder} *`}
                            onChange={(event) => setState({name: event.target.value, error: ""})}
                        />
                        <Input
                            type="email"
                            tabIndex={11}
                            value={state.email}
                            placeholder={`${strings.emailPlaceholder} *`}
                            onChange={(event) => setState({email: event.target.value, error: ""})}
                        />
                        <Input
                            type="tel"
                            tabIndex={12}
                            value={state.phoneNumber}
                            placeholder={strings.phoneNumberPlaceholder}
                            onChange={(event) => setState({phoneNumber: event.target.value, error: ""})}
                        />
                        <Input
                            type="text"
                            tabIndex={13}
                            value={state.companyName}
                            placeholder={strings.companyNamePlaceholder}
                            onChange={(event) => setState({companyName: event.target.value, error: ""})}
                        />
                        <TextArea
                            tabIndex={14}
                            value={state.message}
                            placeholder={`${strings.messagePlaceholder} *`}
                            onChange={(event) => setState({message: event.target.value, error: ""})}
                        />
                        <Note>{strings.requiredNote}</Note>
                        {state.error && <Error>{state.error}</Error>}
                        <Button
                            type="submit"
                            tabIndex={15}
                            disabled={!!(!state.name || !state.email || !state.message)}
                            onClick={async (event) => {
                                event.preventDefault();
                                if (!state.name || !state.email || !state.message) {
                                    setState({error: strings.validationError});
                                    return;
                                }
                                const result = await axios({
                                    method: 'post',
                                    headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'application/json',
                                    },
                                    url: `${urls.contact}`,
                                    data: {
                                        name: state.name,
                                        email: state.email,
                                        phoneNumber: state.phoneNumber,
                                        companyName: state.companyName,
                                        message: state.message,
                                        contactType: "PUBLIC_RELATIONS"
                                    },
                                });

                                if (result.data && result.data.error) {
                                    setState({ error: result.data.error });
                                } else if (result.data && result.data.result) {
                                    setFinished(true);
                                } else {
                                    setState({ error: strings.unknownError });
                                }
                            }}
                        >
                            {strings.submitButton}
                        </Button>
                    </>
                )}
            </Container>
            <Polygon polygonColor="#111213" borderColor="#00ffb3" />
        </PageWrapper>
    );
};

export default ContactPR;
